export const items = [
    {
        "label": "Other specialists",
        "specialties": [
            {
                "label": "Property Manager",
                "description": "Responsible for the management of rental properties, including tenant relations, maintenance, and rent collection.",
                "services": [
                    {
                        "label": "Tenant screening",
                        "keywords": ["tenant background check", "tenant credit check", "tenant screening service", "rental applicant verification", "tenant history report"]
                    },
                    {
                        "label": "Rent collection",
                        "keywords": ["rent payment processing", "online rent collection", "rental income", "automated rent payment", "rent arrears management"]
                    },
                    {
                        "label": "Lease agreement preparation",
                        "keywords": ["lease drafting", "rental contract", "tenancy agreement", "lease documentation", "rental terms"]
                    },
                    {
                        "label": "Property inspections",
                        "keywords": ["property walkthrough", "home inspection", "rental inspection", "move-in inspection", "move-out inspection"]
                    },
                    {
                        "label": "Maintenance coordination",
                        "keywords": ["property repairs", "maintenance scheduling", "service request management", "handyman services", "property upkeep"]
                    },
                    {
                        "label": "Budget management",
                        "keywords": ["property budget planning", "expense tracking", "financial management", "cost analysis", "investment planning"]
                    },
                    {
                        "label": "Legal compliance management",
                        "keywords": ["landlord-tenant laws", "rental property regulations", "compliance checks", "property legal standards", "housing laws"]
                    },
                    {
                        "label": "Eviction process handling",
                        "keywords": ["eviction notice", "tenant eviction", "legal eviction process", "unlawful detainer", "eviction court"]
                    },
                    {
                        "label": "Marketing and advertising",
                        "keywords": ["rental property marketing", "property advertising", "listing rental", "online property listing", "tenant outreach"]
                    },
                    {
                        "label": "Rent adjustment",
                        "keywords": ["rent increase", "rental pricing", "rent decrease", "rental rate adjustment", "market rent analysis"]
                    }
                ]
            },
            {
                "label": "Tile Specialist",
                "description": "Specializes in the installation and maintenance of various types of tile in residential and commercial properties.",
                "services": [
                    {
                        "label": "Ceramic tile installation",
                        "keywords": ["ceramic tile setting", "tile installation service", "ceramic tile flooring", "tile contractor", "ceramic tiling"]
                    },
                    {
                        "label": "Porcelain tile installation",
                        "keywords": ["porcelain tile setting", "porcelain tile flooring", "porcelain tile contractor", "porcelain tiling service", "durable tile installation"]
                    },
                    {
                        "label": "Mosaic tile installation",
                        "keywords": ["mosaic tile setting", "mosaic tile design", "mosaic tile contractor", "artistic tile installation", "mosaic backsplash"]
                    },
                    {
                        "label": "Marble tile installation",
                        "keywords": ["marble tile setting", "marble flooring", "luxury tile installation", "marble contractor", "elegant tile design"]
                    },
                    {
                        "label": "Granite tile installation",
                        "keywords": ["granite tile setting", "granite flooring", "granite tile contractor", "natural stone tile installation", "granite tile service"]
                    },
                    {
                        "label": "Glass tile installation",
                        "keywords": ["glass tile setting", "glass tile backsplash", "glass tile contractor", "modern tile installation", "glass tile service"]
                    },
                    {
                        "label": "Vinyl tile installation",
                        "keywords": ["vinyl tile setting", "vinyl tile flooring", "affordable tile installation", "vinyl tile contractor", "vinyl tile service"]
                    },
                    {
                        "label": "Stone tile installation",
                        "keywords": ["stone tile setting", "stone tile flooring", "natural stone tile contractor", "durable tile installation", "stone tiling service"]
                    },
                    {
                        "label": "Tile backsplash installation",
                        "keywords": ["tile backsplash setting", "kitchen backsplash", "bathroom backsplash", "decorative tile installation", "custom backsplash"]
                    },
                    {
                        "label": "Bathroom tile installation",
                        "keywords": ["bathroom tiling", "tile bathroom floor", "shower tile installation", "bathroom renovation", "tile contractor"]
                    }
                ]
            }
        ]
    }

];