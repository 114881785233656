export const items =
    [
        {
            "label": "Renovation and Construction",
            "specialties": [
                {
                    "label": "Interior Designer",
                    "description": "Specializes in planning, designing, and managing the interior space to make it functional, aesthetic, and aligned with client needs.",
                    "services": [
                        {
                            "label": "Space planning",
                            "keywords": ["interior space planning", "room layout design", "floor plan creation", "space optimization", "functional design"]
                        },
                        {
                            "label": "Color scheme selection",
                            "keywords": ["interior color selection", "paint color consultation", "color coordination", "palette design", "room color planning"]
                        },
                        {
                            "label": "Furniture selection",
                            "keywords": ["interior furniture selection", "furniture sourcing", "room furnishing", "custom furniture design", "furniture arrangement"]
                        },
                        {
                            "label": "Lighting design",
                            "keywords": ["interior lighting design", "lighting fixtures selection", "ambient lighting", "task lighting", "decorative lighting"]
                        },
                        {
                            "label": "Material selection",
                            "keywords": ["interior material selection", "surface material sourcing", "fabric selection", "wallpaper selection", "flooring material"]
                        },
                        {
                            "label": "Interior styling",
                            "keywords": ["home styling", "room decor", "interior accessorizing", "space decoration", "home staging"]
                        },
                        {
                            "label": "Custom cabinetry design",
                            "keywords": ["custom cabinets", "built-in cabinetry", "cabinet design service", "kitchen cabinets", "bathroom vanity design"]
                        },
                        {
                            "label": "Window treatment design",
                            "keywords": ["window coverings", "curtain design", "blinds selection", "drapery consultation", "shutters design"]
                        },
                        {
                            "label": "3D rendering",
                            "keywords": ["interior 3D rendering", "design visualization", "room rendering", "virtual interior design", "3D design modeling"]
                        },
                        {
                            "label": "Sustainable design",
                            "keywords": ["eco-friendly design", "sustainable interior design", "green materials", "energy-efficient design", "sustainable home solutions"]
                        }
                    ]
                },
                {
                    "label": "Home Appraiser",
                    "description": "Provides professional evaluation of the value of residential properties for various purposes, including sales, insurance, and taxation.",
                    "services": [
                        {
                            "label": "Market value appraisal",
                            "keywords": ["home market value", "property valuation", "real estate appraisal", "house value estimate", "residential property valuation"]
                        },
                        {
                            "label": "Pre-sale appraisal",
                            "keywords": ["home pre-sale valuation", "property selling price", "house appraisal for sale", "real estate pre-sale appraisal", "home value before sale"]
                        },
                        {
                            "label": "Mortgage appraisal",
                            "keywords": ["mortgage valuation", "home loan appraisal", "property value for mortgage", "mortgage property inspection", "real estate mortgage appraisal"]
                        },
                        {
                            "label": "Tax assessment",
                            "keywords": ["property tax valuation", "real estate tax assessment", "home tax appraisal", "property taxation value", "taxable property value"]
                        },
                        {
                            "label": "Insurance appraisal",
                            "keywords": ["home insurance valuation", "property insurance appraisal", "insured property value", "house insurance estimate", "real estate insurance appraisal"]
                        },
                        {
                            "label": "Estate planning appraisal",
                            "keywords": ["real estate estate planning", "property value for estate", "inheritance property appraisal", "estate property valuation", "estate tax appraisal"]
                        },
                        {
                            "label": "Divorce property appraisal",
                            "keywords": ["divorce real estate valuation", "property division appraisal", "home value for divorce settlement", "divorce property assessment", "house appraisal during divorce"]
                        },
                        {
                            "label": "Investment property appraisal",
                            "keywords": ["real estate investment appraisal", "property value for investors", "rental property valuation", "investment home appraisal", "investment property value"]
                        },
                        {
                            "label": "New construction appraisal",
                            "keywords": ["new home appraisal", "construction property valuation", "appraisal for new builds", "property value of new construction", "real estate new construction appraisal"]
                        },
                        {
                            "label": "Foreclosure appraisal",
                            "keywords": ["foreclosed property valuation", "home foreclosure appraisal", "foreclosure house value", "real estate foreclosure appraisal", "property value in foreclosure"]
                        }
                    ]
                },
                {
                    "label": "General Contractor",
                    "description": "Oversees the entire construction or renovation projects, managing all phases from planning to completion, including subcontractors and materials.",
                    "services": [
                        {
                            "label": "Project planning",
                            "keywords": ["construction projects planning", "building projects management", "renovation projects planning", "general contractor planning", "construction timeline"]
                        },
                        {
                            "label": "Budget management",
                            "keywords": ["construction budget control", "projects cost management", "building budget planning", "renovation cost estimation", "financial planning for construction"]
                        },
                        {
                            "label": "Subcontractor management",
                            "keywords": ["hiring subcontractors", "subcontractor coordination", "managing subcontractor schedules", "subcontractor agreements", "construction team management"]
                        },
                        {
                            "label": "Material procurement",
                            "keywords": ["construction materials sourcing", "building materials procurement", "material supply for construction", "construction material logistics", "procurement management"]
                        },
                        {
                            "label": "Site supervision",
                            "keywords": ["construction site supervision", "on-site management", "building site monitoring", "job site oversight", "construction safety supervision"]
                        },
                        {
                            "label": "Permit acquisition",
                            "keywords": ["building permits", "construction permit process", "renovation permits", "permit acquisition service", "legal construction documentation"]
                        },
                        {
                            "label": "Quality control",
                            "keywords": ["construction quality assurance", "projects quality inspection", "quality control in construction", "building standards compliance", "construction projects quality management"]
                        },
                        {
                            "label": "Safety management",
                            "keywords": ["construction site safety", "OSHA compliance", "construction safety protocols", "job site safety management", "worker safety supervision"]
                        },
                        {
                            "label": "Scheduling and timelines",
                            "keywords": ["construction scheduling", "projects timeline management", "building projects timelines", "renovation scheduling", "time management in construction"]
                        },
                        {
                            "label": "Cost estimation",
                            "keywords": ["construction cost estimating", "projects cost calculation", "building cost assessment", "renovation budget estimation", "contractor cost evaluation"]
                        }
                    ]
                },
                {
                    "label": "Plumber",
                    "description": "Specializes in installing, repairing, and maintaining plumbing systems in residential, commercial, and industrial settings.",
                    "services": [
                        {
                            "label": "Pipe installation",
                            "keywords": ["pipe fitting", "water pipe installation", "sewer pipe installation", "plumbing pipe setup", "gas pipe fitting"]
                        },
                        {
                            "label": "Leak repair",
                            "keywords": ["pipe leak repair", "faucet leak fixing", "water leak detection", "sewer leak repair", "plumbing leak solutions"]
                        },
                        {
                            "label": "Drain cleaning",
                            "keywords": ["clogged drain cleaning", "sewer drain cleaning", "plumbing drain unclogging", "blocked drain service", "drain maintenance"]
                        },
                        {
                            "label": "Water heater installation",
                            "keywords": ["water heater setup", "hot water system installation", "tankless water heater installation", "gas water heater setup", "electric water heater installation"]
                        },
                        {
                            "label": "Toilet repair",
                            "keywords": ["toilet fixing", "toilet leak repair", "toilet clog repair", "toilet installation service", "plumbing toilet maintenance"]
                        },
                        {
                            "label": "Fixture installation",
                            "keywords": ["sink installation", "faucet installation", "showerhead setup", "bathtub installation", "plumbing fixture fitting"]
                        },
                        {
                            "label": "Sewer line repair",
                            "keywords": ["sewer pipe repair", "main sewer line fixing", "sewer backup repair", "sewer line replacement", "plumbing sewer service"]
                        },
                        {
                            "label": "Gas line installation",
                            "keywords": ["gas pipe fitting", "gas line setup", "gas leak repair", "natural gas line installation", "plumbing gas services"]
                        },
                        {
                            "label": "Water filtration system installation",
                            "keywords": ["water filter setup", "home water purification", "reverse osmosis installation", "whole house water filter", "water treatment system installation"]
                        },
                        {
                            "label": "Emergency plumbing services",
                            "keywords": ["emergency plumber", "24/7 plumbing service", "urgent plumbing repair", "plumbing emergency assistance", "immediate plumbing solutions"]
                        }
                    ]
                },
                {
                    "label": "Electrician",
                    "description": "Specializes in the installation, maintenance, and repair of electrical systems in residential, commercial, and industrial properties.",
                    "services": [
                        {
                            "label": "Electrical wiring installation",
                            "keywords": ["electrical wiring setup", "home wiring installation", "commercial wiring services", "new wiring installation", "building electrical wiring"]
                        },
                        {
                            "label": "Circuit breaker repair",
                            "keywords": ["circuit breaker fixing", "electrical panel repair", "breaker box service", "fuse box repair", "electrical breaker replacement"]
                        },
                        {
                            "label": "Lighting installation",
                            "keywords": ["indoor lighting setup", "outdoor lighting installation", "LED light installation", "custom lighting solutions", "residential lighting installation"]
                        },
                        {
                            "label": "Electrical outlet installation",
                            "keywords": ["outlet setup", "power socket installation", "electrical outlet repair", "GFCI outlet installation", "home outlet services"]
                        },
                        {
                            "label": "Ceiling fan installation",
                            "keywords": ["ceiling fan wiring", "fan setup service", "ceiling fan mounting", "electric fan installation", "fan replacement service"]
                        },
                        {
                            "label": "Home automation system installation",
                            "keywords": ["smart home setup", "home automation wiring", "automated lighting installation", "smart device integration", "home automation services"]
                        },
                        {
                            "label": "Surge protection installation",
                            "keywords": ["surge protector setup", "home surge protection", "electrical surge control", "whole house surge protection", "appliance surge protection"]
                        },
                        {
                            "label": "Electrical inspection and testing",
                            "keywords": ["home electrical inspection", "commercial electrical testing", "safety electrical check", "building electrical assessment", "wiring safety inspection"]
                        },
                        {
                            "label": "Generator installation",
                            "keywords": ["backup generator setup", "standby generator installation", "emergency power system", "home generator installation", "portable generator wiring"]
                        },
                        {
                            "label": "Emergency electrical services",
                            "keywords": ["emergency electrician", "24/7 electrical service", "urgent electrical repair", "power outage services", "electrical emergency assistance"]
                        }
                    ]
                },
                {
                    "label": "Carpenter",
                    "description": "Specializes in constructing, installing, and repairing structures and fixtures made of wood, plywood, and other materials.",
                    "services": [
                        {
                            "label": "Custom furniture building",
                            "keywords": ["custom furniture design", "bespoke furniture", "handmade furniture", "wooden furniture crafting", "custom woodworking projects"]
                        },
                        {
                            "label": "Cabinet installation",
                            "keywords": ["cabinet fitting", "kitchen cabinet installation", "bathroom cabinet setup", "custom cabinet making", "cabinetry services"]
                        },
                        {
                            "label": "Door and window frame installation",
                            "keywords": ["door frame fitting", "window frame installation", "door and window replacement", "frame carpentry", "custom frame construction"]
                        },
                        {
                            "label": "Deck and patio construction",
                            "keywords": ["deck building", "patio construction", "outdoor deck carpentry", "wooden patio setup", "deck repair and maintenance"]
                        },
                        {
                            "label": "Flooring installation",
                            "keywords": ["wooden floor installation", "hardwood flooring setup", "laminate floor fitting", "custom flooring services", "carpentry floor installation"]
                        },
                        {
                            "label": "Trim and molding installation",
                            "keywords": ["crown molding fitting", "baseboard installation", "decorative trim services", "custom molding setup", "wood trim carpentry"]
                        },
                        {
                            "label": "Staircase building and repair",
                            "keywords": ["wooden staircase construction", "staircase repair services", "custom stair design", "carpentry staircase installation", "handrail setup"]
                        },
                        {
                            "label": "Shelving and storage solutions",
                            "keywords": ["custom shelving", "storage unit construction", "built-in shelves", "wooden storage solutions", "carpentry shelving services"]
                        },
                        {
                            "label": "Wood framing",
                            "keywords": ["home wood framing", "construction framing services", "carpentry framing", "custom frame building", "structural wood framing"]
                        },
                        {
                            "label": "Wood restoration and repair",
                            "keywords": ["wood furniture restoration", "antique wood repair", "wood surface refinishing", "carpentry restoration services", "wood repair and maintenance"]
                        }
                    ]
                },
                {
                    "label": "Painter",
                    "description": "Specializes in applying paint, stain, varnish, and other finishes to walls, ceilings, and other surfaces to protect and beautify structures.",
                    "services": [
                        {
                            "label": "Interior painting",
                            "keywords": ["indoor painting", "room painting", "wall painting services", "ceiling painting", "home interior painting"]
                        },
                        {
                            "label": "Exterior painting",
                            "keywords": ["outdoor painting", "house exterior painting", "building facade painting", "external wall painting", "home exterior repainting"]
                        },
                        {
                            "label": "Wallpaper installation",
                            "keywords": ["wallpaper fitting", "decorative wallpapering", "wall covering installation", "custom wallpaper services", "interior wallpaper setup"]
                        },
                        {
                            "label": "Staining and varnishing",
                            "keywords": ["wood staining", "furniture varnishing", "floor staining services", "wood finishing", "carpentry staining and varnishing"]
                        },
                        {
                            "label": "Textured painting",
                            "keywords": ["decorative painting", "textured wall painting", "custom texture painting", "faux finish painting", "wall texture application"]
                        },
                        {
                            "label": "Ceiling painting",
                            "keywords": ["ceiling repainting", "custom ceiling painting", "interior ceiling finishing", "room ceiling decoration", "decorative ceiling painting"]
                        },
                        {
                            "label": "Spray painting",
                            "keywords": ["spray paint application", "commercial spray painting", "residential spray painting", "automotive spray painting", "industrial spray painting"]
                        },
                        {
                            "label": "Priming surfaces",
                            "keywords": ["surface priming", "wall priming", "paint preparation", "base coating", "primer application"]
                        },
                        {
                            "label": "Epoxy coating",
                            "keywords": ["epoxy floor coating", "industrial epoxy painting", "garage floor epoxy", "decorative epoxy coating", "epoxy surface protection"]
                        },
                        {
                            "label": "Paint stripping",
                            "keywords": ["paint removal", "surface paint stripping", "wall paint stripping", "furniture paint removal", "old paint stripping services"]
                        }
                    ]
                },
                {
                    "label": "Roofer",
                    "description": "Specializes in the installation, repair, and maintenance of roofs, ensuring protection from weather and environmental conditions.",
                    "services": [
                        {
                            "label": "Roof installation",
                            "keywords": ["new roof setup", "roof construction", "roofing installation services", "home roof installation", "commercial roofing installation"]
                        },
                        {
                            "label": "Roof repair",
                            "keywords": ["leaky roof repair", "shingle replacement", "roof damage fixing", "roof patching", "emergency roof repair"]
                        },
                        {
                            "label": "Roof inspection",
                            "keywords": ["roof condition assessment", "home roof inspection", "commercial roof inspection", "roof safety check", "roof damage evaluation"]
                        },
                        {
                            "label": "Roof maintenance",
                            "keywords": ["roof cleaning", "roof upkeep", "roofing maintenance services", "preventative roof care", "roofing system maintenance"]
                        },
                        {
                            "label": "Gutter installation",
                            "keywords": ["gutter setup", "rain gutter installation", "gutter replacement", "downspout installation", "seamless gutter services"]
                        },
                        {
                            "label": "Roof insulation",
                            "keywords": ["roof insulation installation", "attic insulation", "thermal roof insulation", "energy-efficient roofing", "insulation roof services"]
                        },
                        {
                            "label": "Flat roofing",
                            "keywords": ["flat roof installation", "flat roof repair", "commercial flat roofing", "flat roof maintenance", "flat roofing systems"]
                        },
                        {
                            "label": "Metal roofing",
                            "keywords": ["metal roof installation", "steel roofing", "aluminum roofing", "metal roof repair", "residential metal roofing"]
                        },
                        {
                            "label": "Slate roofing",
                            "keywords": ["slate roof installation", "natural slate roofing", "slate tile replacement", "slate roof maintenance", "custom slate roofing"]
                        },
                        {
                            "label": "Solar panel roofing",
                            "keywords": ["solar roof installation", "solar panel setup", "solar energy roofing", "integrated solar panels", "solar roof services"]
                        }
                    ]
                },
                {
                    "label": "Tiler",
                    "description": "Specializes in laying and fixing tiles on floors, walls, and other surfaces in residential, commercial, and industrial properties.",
                    "services": [
                        {
                            "label": "Floor tiling",
                            "keywords": ["floor tile installation", "ceramic floor tiles", "porcelain tile flooring", "stone tile flooring", "residential floor tiling"]
                        },
                        {
                            "label": "Wall tiling",
                            "keywords": ["wall tile installation", "bathroom wall tiling", "kitchen backsplash tiling", "decorative wall tiles", "commercial wall tiling"]
                        },
                        {
                            "label": "Mosaic tiling",
                            "keywords": ["mosaic tile installation", "custom mosaic design", "mosaic wall tiling", "mosaic floor tiles", "artistic tiling services"]
                        },
                        {
                            "label": "Grout repair",
                            "keywords": ["grout fixing", "tile grout repair", "grout replacement", "grout cleaning services", "tile grout maintenance"]
                        },
                        {
                            "label": "Tile repair and replacement",
                            "keywords": ["broken tile repair", "tile replacement services", "cracked tile fixing", "tile patching", "tile damage repair"]
                        },
                        {
                            "label": "Outdoor tiling",
                            "keywords": ["patio tiling", "outdoor tile installation", "garden tiling services", "poolside tiling", "outdoor ceramic tiles"]
                        },
                        {
                            "label": "Waterproofing for tiles",
                            "keywords": ["tile waterproofing", "bathroom waterproofing", "shower tile waterproofing", "waterproof tile sealant", "moisture barrier for tiles"]
                        },
                        {
                            "label": "Tile leveling",
                            "keywords": ["tile leveling system", "floor leveling for tiles", "wall tile leveling", "even tile installation", "precision tile fitting"]
                        },
                        {
                            "label": "Tile design consultation",
                            "keywords": ["tile design advice", "custom tile patterns", "tile layout planning", "decorative tile design", "tile selection guidance"]
                        },
                        {
                            "label": "Tile cleaning and maintenance",
                            "keywords": ["tile cleaning services", "tile maintenance", "tile polishing", "tile surface cleaning", "long-term tile care"]
                        }
                    ]
                },
                {
                    "label": "Mason",
                    "description": "Specializes in building and repairing structures made of bricks, stones, concrete blocks, and other masonry materials.",
                    "services": [
                        {
                            "label": "Bricklaying",
                            "keywords": ["brick wall construction", "bricklaying services", "masonry brickwork", "brick structure building", "brick repair and maintenance"]
                        },
                        {
                            "label": "Stone masonry",
                            "keywords": ["stone wall construction", "natural stone masonry", "stonework services", "stone repair and maintenance", "stone structure building"]
                        },
                        {
                            "label": "Concrete block installation",
                            "keywords": ["concrete block wall", "block masonry", "cinder block installation", "concrete block construction", "block wall repair"]
                        },
                        {
                            "label": "Chimney repair",
                            "keywords": ["chimney masonry repair", "chimney rebuilding", "chimney brick repair", "chimney structure maintenance", "masonry chimney services"]
                        },
                        {
                            "label": "Foundation repair",
                            "keywords": ["masonry foundation repair", "concrete foundation services", "foundation crack repair", "masonry structural support", "foundation stabilization"]
                        },
                        {
                            "label": "Paving installation",
                            "keywords": ["paver installation", "stone paving services", "concrete paving", "driveway paving", "patio paving"]
                        },
                        {
                            "label": "Retaining wall construction",
                            "keywords": ["retaining wall masonry", "stone retaining walls", "concrete retaining wall construction", "garden retaining wall", "structural retaining walls"]
                        },
                        {
                            "label": "Masonry restoration",
                            "keywords": ["historic masonry restoration", "brickwork restoration", "stone restoration services", "masonry surface restoration", "masonry repair and restoration"]
                        },
                        {
                            "label": "Stucco application",
                            "keywords": ["stucco wall application", "exterior stucco services", "decorative stucco", "stucco repair and maintenance", "masonry stucco installation"]
                        },
                        {
                            "label": "Tuckpointing",
                            "keywords": ["masonry tuckpointing", "brick joint repair", "mortar joint replacement", "brickwork tuckpointing", "masonry joint restoration"]
                        }
                    ]
                }
            ]
        },
        {
            "label": "Home Services",
            "specialties": [
                {
                    "label": "Handyman",
                    "description": "Provides general repair and maintenance services for homes, including fixing doors, assembling furniture, and minor plumbing or electrical work.",
                    "services": [
                        {
                            "label": "Furniture assembly",
                            "keywords": ["furniture setup", "IKEA assembly", "furniture installation", "home furniture building", "furniture repair"]
                        },
                        {
                            "label": "Minor plumbing repairs",
                            "keywords": ["leaky faucet repair", "pipe fixing", "toilet repair", "plumbing maintenance", "home plumbing services"]
                        },
                        {
                            "label": "Electrical fixes",
                            "keywords": ["light fixture installation", "outlet repair", "switch replacement", "home electrical maintenance", "minor electrical work"]
                        },
                        {
                            "label": "Door and window repair",
                            "keywords": ["door hinge repair", "window fixing", "door lock installation", "window maintenance", "home entry repair"]
                        },
                        {
                            "label": "Painting touch-ups",
                            "keywords": ["wall painting", "touch-up painting", "interior painting", "exterior painting", "home painting services"]
                        }
                    ]
                },
                {
                    "label": "Housekeeper",
                    "description": "Provides cleaning and maintenance services for homes, including dusting, vacuuming, mopping, and organizing.",
                    "services": [
                        {
                            "label": "Regular cleaning",
                            "keywords": ["house cleaning", "home maintenance", "dusting and vacuuming", "weekly cleaning", "residential cleaning"]
                        },
                        {
                            "label": "Deep cleaning",
                            "keywords": ["thorough cleaning", "spring cleaning", "detailed home cleaning", "intensive cleaning", "deep home maintenance"]
                        },
                        {
                            "label": "Laundry services",
                            "keywords": ["clothes washing", "ironing services", "laundry folding", "home laundry", "residential laundry"]
                        },
                        {
                            "label": "Organization services",
                            "keywords": ["home organization", "closet organizing", "decluttering services", "home tidying", "residential organization"]
                        },
                        {
                            "label": "Special event cleaning",
                            "keywords": ["post-party cleaning", "event cleanup", "holiday cleaning", "party cleanup", "event maintenance"]
                        }
                    ]
                },
                {
                    "label": "Landscaper",
                    "description": "Specializes in designing, installing, and maintaining outdoor spaces, including gardens, lawns, and patios.",
                    "services": [
                        {
                            "label": "Lawn care",
                            "keywords": ["lawn mowing", "grass trimming", "lawn fertilization", "weed control", "lawn maintenance"]
                        },
                        {
                            "label": "Garden design",
                            "keywords": ["landscape design", "garden planning", "outdoor space design", "plant selection", "garden layout"]
                        },
                        {
                            "label": "Tree and shrub care",
                            "keywords": ["tree pruning", "shrub trimming", "tree removal", "plant health care", "tree maintenance"]
                        },
                        {
                            "label": "Irrigation system installation",
                            "keywords": ["sprinkler system setup", "irrigation system design", "lawn watering system", "drip irrigation", "outdoor watering solutions"]
                        },
                        {
                            "label": "Hardscaping",
                            "keywords": ["patio construction", "walkway installation", "retaining walls", "outdoor stonework", "landscape structures"]
                        }
                    ]
                },
                {
                    "label": "Pool Maintenance Specialist",
                    "description": "Provides cleaning, chemical balancing, and repair services for swimming pools.",
                    "services": [
                        {
                            "label": "Pool cleaning",
                            "keywords": ["pool vacuuming", "pool skimming", "pool brushing", "pool filter cleaning", "pool water maintenance"]
                        },
                        {
                            "label": "Chemical balancing",
                            "keywords": ["pool pH adjustment", "chlorine level management", "pool water testing", "chemical treatment", "pool sanitation"]
                        },
                        {
                            "label": "Pool repair",
                            "keywords": ["pool pump repair", "pool liner replacement", "leak detection", "pool equipment repair", "pool plumbing fixes"]
                        },
                        {
                            "label": "Winterization",
                            "keywords": ["pool winter closing", "winter pool cover installation", "pool winter maintenance", "cold weather pool care", "winter pool preparation"]
                        },
                        {
                            "label": "Pool opening",
                            "keywords": ["spring pool opening", "pool startup services", "pool spring cleaning", "pool preparation for summer", "pool seasonal maintenance"]
                        }
                    ]
                },
                {
                    "label": "Pest Control Specialist",
                    "description": "Provides extermination and prevention services for pests such as insects, rodents, and termites.",
                    "services": [
                        {
                            "label": "Insect control",
                            "keywords": ["ant extermination", "cockroach removal", "spider control", "bed bug treatment", "insect pest management"]
                        },
                        {
                            "label": "Rodent control",
                            "keywords": ["mouse removal", "rat extermination", "rodent prevention", "rodent trapping", "rodent proofing"]
                        },
                        {
                            "label": "Termite control",
                            "keywords": ["termite inspection", "termite treatment", "termite prevention", "termite damage repair", "termite extermination"]
                        },
                        {
                            "label": "Wildlife removal",
                            "keywords": ["raccoon removal", "squirrel control", "bat removal", "wildlife exclusion", "animal trapping"]
                        },
                        {
                            "label": "Preventative pest control",
                            "keywords": ["pest prevention", "regular pest inspections", "pest-proofing homes", "pest control maintenance", "long-term pest management"]
                        }
                    ]
                },
                {
                    "label": "Window Cleaner",
                    "description": "Specializes in cleaning windows for residential and commercial properties.",
                    "services": [
                        {
                            "label": "Residential window cleaning",
                            "keywords": ["home window cleaning", "interior window cleaning", "exterior window cleaning", "window washing", "residential window maintenance"]
                        },
                        {
                            "label": "Commercial window cleaning",
                            "keywords": ["office window cleaning", "storefront window cleaning"]
                        }
                    ]
                }
            ]
        },
        {
            "label": "Personal Services",
            "specialties": [
                {
                    "label": "Yoga/Pilates Instructor",
                    "description": "Provides yoga and Pilates instruction and workout routines for individuals and groups.",
                    "services": [
                        {
                            "label": "Pilates training",
                            "keywords": ["Pilates instruction", "Pilates workout routines", "Pilates core strengthening", "private Pilates sessions", "group Pilates classes"]
                        },
                        {
                            "label": "Yoga for specific needs",
                            "keywords": ["prenatal yoga", "yoga for seniors", "yoga for stress relief", "therapeutic yoga", "yoga for injury recovery"]
                        },
                        {
                            "label": "Meditation guidance",
                            "keywords": ["mindfulness meditation", "stress reduction techniques", "relaxation exercises", "meditation coaching", "mental wellness practices"]
                        }
                    ]
                },
                {
                    "label": "Massage Therapist",
                    "description": "Provides therapeutic massage services to relieve pain, reduce stress, and promote relaxation.",
                    "services": [
                        {
                            "label": "Swedish massage",
                            "keywords": ["relaxation massage", "stress relief massage", "full-body massage", "therapeutic massage", "muscle relaxation"]
                        },
                        {
                            "label": "Deep tissue massage",
                            "keywords": ["muscle tension relief", "chronic pain massage", "deep muscle therapy", "intense pressure massage", "targeted muscle work"]
                        },
                        {
                            "label": "Sports massage",
                            "keywords": ["athlete massage", "injury prevention massage", "post-workout recovery", "sports therapy", "muscle recovery massage"]
                        },
                        {
                            "label": "Prenatal massage",
                            "keywords": ["pregnancy massage", "maternity massage", "prenatal relaxation", "pregnancy pain relief", "safe prenatal therapy"]
                        },
                        {
                            "label": "Aromatherapy massage",
                            "keywords": ["essential oil massage", "aroma relaxation", "therapeutic aromatherapy", "stress relief with oils", "relaxing scent therapy"]
                        }
                    ]
                },
                {
                    "label": "Hair Stylist/Barber",
                    "description": "Provides hair cutting, styling, and grooming services for men, women, and children.",
                    "services": [
                        {
                            "label": "Haircuts",
                            "keywords": ["men's haircuts", "women's haircuts", "children's haircuts", "hair trimming", "hair shaping"]
                        },
                        {
                            "label": "Hair coloring",
                            "keywords": ["hair dyeing", "balayage", "highlights", "ombre", "root touch-ups"]
                        },
                        {
                            "label": "Hair styling",
                            "keywords": ["wedding hairstyles", "event hairstyling", "updos", "blowouts", "curling and straightening"]
                        },
                        {
                            "label": "Beard grooming",
                            "keywords": ["beard trimming", "beard shaping", "beard styling", "facial hair maintenance", "beard care"]
                        },
                        {
                            "label": "Scalp treatments",
                            "keywords": ["scalp massage", "dandruff treatment", "scalp hydration", "scalp health", "hair follicle therapy"]
                        }
                    ]
                },
                {
                    "label": "Makeup Artist",
                    "description": "Provides professional makeup application for events, photoshoots, and everyday looks.",
                    "services": [
                        {
                            "label": "Bridal makeup",
                            "keywords": ["wedding makeup", "bridal party makeup", "bridal glam", "wedding day makeup", "bridal beauty"]
                        },
                        {
                            "label": "Event makeup",
                            "keywords": ["party makeup", "special occasion makeup", "evening makeup", "event glam", "red carpet makeup"]
                        },
                        {
                            "label": "Photoshoot makeup",
                            "keywords": ["studio makeup", "camera-ready makeup", "editorial makeup", "photography makeup", "professional makeup"]
                        },
                        {
                            "label": "Everyday makeup",
                            "keywords": ["natural makeup", "daily makeup routine", "light makeup application", "quick makeup", "simple makeup"]
                        },
                        {
                            "label": "Makeup lessons",
                            "keywords": ["makeup tutorials", "makeup application tips", "personalized makeup lessons", "makeup techniques", "beauty coaching"]
                        }
                    ]
                },
                {
                    "label": "Nanny/Babysitter",
                    "description": "Provides childcare services, including supervision, feeding, and engaging children in activities.",
                    "services": [
                        {
                            "label": "Child supervision",
                            "keywords": ["babysitting", "childcare", "kid supervision", "after-school care", "evening babysitting"]
                        },
                        {
                            "label": "Meal preparation for kids",
                            "keywords": ["children's meals", "healthy snacks for kids", "kid-friendly cooking", "meal planning for children", "child nutrition"]
                        },
                        {
                            "label": "Homework help",
                            "keywords": ["tutoring for kids", "schoolwork assistance", "homework support", "educational activities", "learning help"]
                        },
                        {
                            "label": "Playtime activities",
                            "keywords": ["indoor games", "outdoor play", "creative activities", "child entertainment", "fun learning"]
                        },
                        {
                            "label": "Overnight care",
                            "keywords": ["nighttime babysitting", "overnight supervision", "sleepover care", "late-night childcare", "overnight nanny"]
                        }
                    ]
                },
                {
                    "label": "Elderly Caregiver",
                    "description": "Provides assistance and companionship for elderly individuals, including personal care and household tasks.",
                    "services": [
                        {
                            "label": "Personal care",
                            "keywords": ["bathing assistance", "dressing help", "grooming support", "mobility assistance", "elderly hygiene"]
                        },
                        {
                            "label": "Medication management",
                            "keywords": ["medication reminders", "pill organization", "prescription management", "health monitoring", "elderly health care"]
                        },
                        {
                            "label": "Companionship",
                            "keywords": ["elderly companionship", "social interaction", "emotional support", "conversation partner", "elderly engagement"]
                        },
                        {
                            "label": "Meal preparation",
                            "keywords": ["elderly meal planning", "healthy cooking for seniors", "diet-specific meals", "meal delivery assistance", "nutrition for elderly"]
                        },
                        {
                            "label": "Household chores",
                            "keywords": ["light housekeeping", "laundry assistance", "grocery shopping", "errand running", "home maintenance"]
                        }
                    ]
                },
                {
                    "label": "Pet Groomer",
                    "description": "Provides grooming services for pets, including bathing, haircuts, and nail trimming.",
                    "services": [
                        {
                            "label": "Bathing and brushing",
                            "keywords": ["pet bathing", "dog grooming", "cat grooming", "fur brushing", "pet hygiene"]
                        },
                        {
                            "label": "Haircuts",
                            "keywords": ["pet haircuts", "dog trimming", "cat styling", "breed-specific cuts", "pet grooming styles"]
                        },
                        {
                            "label": "Nail trimming",
                            "keywords": ["pet nail clipping", "dog nail care", "cat nail trimming", "nail filing", "paw care"]
                        },
                        {
                            "label": "Ear cleaning",
                            "keywords": ["pet ear hygiene", "ear infection prevention", "ear cleaning services", "pet ear care", "ear maintenance"]
                        },
                        {
                            "label": "Teeth cleaning",
                            "keywords": ["pet dental care", "teeth brushing", "oral hygiene for pets", "dental cleaning", "pet dental health"]
                        }
                    ]
                },
                {
                    "label": "Dog Walker",
                    "description": "Provides dog walking services to ensure pets get regular exercise and outdoor time.",
                    "services": [
                        {
                            "label": "Regular walks",
                            "keywords": ["daily dog walks", "routine walking", "pet exercise", "dog walking schedule", "outdoor walks"]
                        },
                        {
                            "label": "Group walks",
                            "keywords": ["dog group walks", "social walking", "pack walks", "group exercise", "dog socialization"]
                        },
                        {
                            "label": "Playtime",
                            "keywords": ["dog play sessions", "outdoor play", "fetch games", "dog park visits", "pet entertainment"]
                        },
                        {
                            "label": "Feeding and hydration",
                            "keywords": ["pet feeding", "water breaks", "meal assistance", "hydration for dogs", "pet care during walks"]
                        },
                        {
                            "label": "Overnight care",
                            "keywords": ["pet sitting", "overnight dog care", "late-night walks", "pet supervision", "extended dog walking"]
                        }
                    ]
                }
            ]
        },
        {
            "label": "Home Improvement",
            "specialties": [
                {
                    "label": "Interior Designer",
                    "description": "Specializes in planning, designing, and managing the interior space to make it functional, aesthetic, and aligned with client needs.",
                    "services": [
                        {
                            "label": "Space planning",
                            "keywords": ["interior space planning", "room layout design", "floor plan creation", "space optimization", "functional design"]
                        },
                        {
                            "label": "Color scheme selection",
                            "keywords": ["interior color selection", "paint color consultation", "color coordination", "palette design", "room color planning"]
                        },
                        {
                            "label": "Furniture selection",
                            "keywords": ["interior furniture selection", "furniture sourcing", "room furnishing", "custom furniture design", "furniture arrangement"]
                        },
                        {
                            "label": "Lighting design",
                            "keywords": ["interior lighting design", "lighting fixtures selection", "ambient lighting", "task lighting", "decorative lighting"]
                        },
                        {
                            "label": "Material selection",
                            "keywords": ["interior material selection", "surface material sourcing", "fabric selection", "wallpaper selection", "flooring material"]
                        }
                    ]
                },
                {
                    "label": "Home Stager",
                    "description": "Prepares homes for sale by enhancing their appeal to potential buyers through furniture arrangement and decor.",
                    "services": [
                        {
                            "label": "Furniture arrangement",
                            "keywords": ["room layout optimization", "furniture placement", "space utilization", "home staging", "interior arrangement"]
                        },
                        {
                            "label": "Decor selection",
                            "keywords": ["decor styling", "home accessories", "decorative items", "staging decor", "interior styling"]
                        },
                        {
                            "label": "Decluttering",
                            "keywords": ["home organization", "clutter removal", "space clearing", "home tidying", "decluttering services"]
                        },
                        {
                            "label": "Lighting adjustments",
                            "keywords": ["lighting optimization", "natural light enhancement", "light fixture placement", "home lighting design", "staging lighting"]
                        },
                        {
                            "label": "Curb appeal enhancement",
                            "keywords": ["exterior staging", "front yard decor", "home exterior styling", "curb appeal improvement", "outdoor staging"]
                        }
                    ]
                },
                {
                    "label": "Smart Home Technician",
                    "description": "Installs and configures smart home devices, such as security systems, thermostats, and lighting controls.",
                    "services": [
                        {
                            "label": "Smart security systems",
                            "keywords": ["home security installation", "smart locks", "security cameras", "alarm systems", "home automation security"]
                        },
                        {
                            "label": "Smart thermostats",
                            "keywords": ["thermostat installation", "energy-efficient heating", "smart temperature control", "home climate control", "HVAC automation"]
                        },
                        {
                            "label": "Smart lighting",
                            "keywords": ["lighting automation", "smart bulbs", "lighting control systems", "home lighting setup", "smart home lighting"]
                        },
                        {
                            "label": "Voice assistant integration",
                            "keywords": ["Alexa setup", "Google Home installation", "voice control systems", "smart home assistants", "voice-activated devices"]
                        },
                        {
                            "label": "Home network setup",
                            "keywords": ["Wi-Fi installation", "network configuration", "internet setup", "home networking", "smart home connectivity"]
                        }
                    ]
                },
                {
                    "label": "Home Theater Installer",
                    "description": "Designs and installs home theater systems, including audio, video, and seating arrangements.",
                    "services": [
                        {
                            "label": "Audio system installation",
                            "keywords": ["surround sound setup", "home audio systems", "speaker installation", "soundbar setup", "home theater audio"]
                        },
                        {
                            "label": "Video system setup",
                            "keywords": ["projector installation", "TV mounting", "home theater screens", "4K setup", "home cinema video"]
                        },
                        {
                            "label": "Seating arrangement",
                            "keywords": ["theater seating", "recliner installation", "home theater chairs", "seating layout", "comfortable viewing"]
                        },
                        {
                            "label": "Acoustic optimization",
                            "keywords": ["soundproofing", "acoustic panels", "room acoustics", "sound optimization", "home theater sound"]
                        },
                        {
                            "label": "Lighting control",
                            "keywords": ["theater lighting", "dimmable lights", "mood lighting", "lighting automation", "home theater ambiance"]
                        }
                    ]
                },
                {
                    "label": "Closet Organizer",
                    "description": "Designs and organizes closets to maximize storage space and improve functionality.",
                    "services": [
                        {
                            "label": "Custom closet design",
                            "keywords": ["closet layout planning", "storage solutions", "custom shelving", "closet organization", "wardrobe optimization"]
                        },
                        {
                            "label": "Storage solutions",
                            "keywords": ["shoe racks", "closet drawers", "hanging organizers", "storage bins", "closet accessories"]
                        },
                        {
                            "label": "Decluttering",
                            "keywords": ["closet cleaning", "wardrobe organization", "clutter removal", "space optimization", "closet tidying"]
                        },
                        {
                            "label": "Seasonal organization",
                            "keywords": ["seasonal storage", "closet rotation", "winter wardrobe organization", "summer closet setup", "seasonal clothing management"]
                        },
                        {
                            "label": "Walk-in closet design",
                            "keywords": ["luxury closet design", "walk-in wardrobe", "custom closet solutions", "high-end closet organization", "premium storage"]
                        }
                    ]
                },
                {
                    "label": "Feng Shui Consultant",
                    "description": "Provides advice on arranging living spaces to promote harmony, balance, and positive energy flow.",
                    "services": [
                        {
                            "label": "Space analysis",
                            "keywords": ["energy flow assessment", "feng shui evaluation", "home energy analysis", "space harmony", "feng shui consultation"]
                        },
                        {
                            "label": "Furniture arrangement",
                            "keywords": ["feng shui layout", "optimal furniture placement", "energy flow optimization", "room arrangement", "feng shui design"]
                        },
                        {
                            "label": "Color and decor advice",
                            "keywords": ["feng shui colors", "decor selection", "energy-enhancing decor", "feng shui accessories", "harmonious design"]
                        },
                        {
                            "label": "Office feng shui",
                            "keywords": ["workspace optimization", "office energy flow", "feng shui for productivity", "workplace harmony", "office layout"]
                        },
                        {
                            "label": "Garden feng shui",
                            "keywords": ["outdoor energy flow", "garden layout", "feng shui landscaping", "harmonious outdoor spaces", "garden design"]
                        }
                    ]
                }
            ]
        }
    ];