import styles from './PortfolioGrid.module.css';
import PortfolioCard from "./PortfolioCard";
import {Box, Button, IconButton, Modal, Stack, Typography, CircularProgress, Tooltip} from "@mui/material";
import {Add} from "@mui/icons-material";
import ProjectEditorModal from "./ProjectEditorModal";
import CloseIcon from '@mui/icons-material/Close';
import {useState, useEffect, useCallback} from 'react';
import {extendedProfileApi} from 'src/pages/cabinet/profiles/my/data/extendedProfileApi';

export const usePortfolio = (userId) => {
    const [portfolio, setPortfolio] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchPortfolio = useCallback(async () => {
        try {
            setLoading(true);
            const data = await extendedProfileApi.getPortfolio(userId);
            setPortfolio(data || []);
            setError(null);
        } catch (err) {
            setError(err.message);
            console.error('Failed to fetch portfolio:', err);
        } finally {
            setLoading(false);
        }
    }, [userId]);

    useEffect(() => {
        fetchPortfolio();
    }, [fetchPortfolio]);

    const addProject = async (project) => {
        try {
            await fetchPortfolio();
        } catch (err) {
            throw err;
        }
    };

    const updateProject = async () => {
        try {
            await fetchPortfolio();
        } catch (err) {
            throw err;
        }
    };

    const deleteProject = async () => {
        try {
            await fetchPortfolio();
        } catch (err) {
            throw err;
        }
    };

    return {
        portfolio,
        loading,
        error,
        addProject,
        updateProject,
        deleteProject,
        refresh: fetchPortfolio
    };
};

const PortfolioGrid = ({setProfile, onCardClick, userId, isMyProfile, updateProfileState, setUpdateProfileState}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [currentPortfolio, setCurrentPortfolio] = useState(null);
    const [editIndex, setEditIndex] = useState(null);

    const {
        portfolio,
        loading,
        error,
        addProject,
        updateProject,
        deleteProject
    } = usePortfolio(userId);

    useEffect(() => {
        if (updateProfileState) {
            updateProject();
            setUpdateProfileState(false);
        }
    }, [updateProfileState]);

    const openEditDialog = (index) => {
        setEditIndex(index);
        setCurrentPortfolio(portfolio[index]);
        setDialogOpen(true);
    };

    const handleSavePortfolio = async (newPortfolio) => {
        try {
            if (editIndex !== null) {
                await updateProject(newPortfolio.id, newPortfolio);
            } else {
                await addProject(newPortfolio);
            }
            setDialogOpen(false);
            setCurrentPortfolio(null);
            setEditIndex(null);
        } catch (err) {
            console.error('Error saving portfolio:', err);
        }
    };

    const handleDelete = async (port) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            try {
                await deleteProject(port.id);
            } catch (err) {
                console.error('Error deleting portfolio:', err);
            }
        }
    };

    const openGallery = () => setIsGalleryOpen(true);
    const closeGallery = () => setIsGalleryOpen(false);

    if (loading) {
        return (
            <Box>
                <Stack>
                    <Typography variant="h6" color="text.secondary" gutterBottom sx={{mb: 1}}>
                        PORTFOLIO
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                        <CircularProgress/>
                    </Box>
                </Stack>
            </Box>
        );
    }

    if (error) {
        return (
            <Box>
                <Stack>
                    <Typography variant="h6" color="text.secondary" gutterBottom sx={{mb: 1}}>
                        PORTFOLIO
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                        <Typography color="error">Error loading portfolio: {error}</Typography>
                    </Box>
                </Stack>
            </Box>
        );
    }

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                    PORTFOLIO
                </Typography>
                {isMyProfile && (
                    <Tooltip title="Add New Portfolio Project">
                        <Add color="success"
                             onClick={() => {
                                 setCurrentPortfolio(null);
                                 setDialogOpen(true);
                             }}
                             sx={{
                                 cursor: "pointer",
                                 transition: "transform 0.2s ease-in-out",
                                 "&:hover": {
                                     transform: "scale(1.1)",
                                 },
                             }}
                        />
                    </Tooltip>)}
            </Stack>

            {(!portfolio || portfolio.length === 0) &&
                <Typography color="text.secondary" fontSize="14px">There is no completed portfolio
                    information</Typography>}

            <div className={styles.gridContainer}>
                {portfolio?.slice(0, 3).map((port, index) => (
                    <PortfolioCard
                        key={port.id}
                        project={port}
                        onClick={onCardClick}
                        onEdit={() => openEditDialog(index)}
                        onDelete={handleDelete}
                        userId={userId}
                        isMyProfile={isMyProfile}
                    />
                ))}
            </div>

            {portfolio && portfolio.length > 3 && (
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={openGallery}
                    sx={{
                        marginTop: 2,
                        '&:hover': {backgroundColor: 'action.hover'}
                    }}
                >
                    View All Projects ({portfolio?.length})
                </Button>)}

            <Modal open={isGalleryOpen} onClose={closeGallery}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4,
                }}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton onClick={closeGallery}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        All Projects
                    </Typography>
                    <div className={styles.gridContainer}>
                        {portfolio?.map((port, index) => (
                            <PortfolioCard
                                key={port.id}
                                project={port}
                                onClick={onCardClick}
                                onEdit={() => openEditDialog(index)}
                                onDelete={handleDelete}
                                userId={userId}
                                isMyProfile={isMyProfile}
                            />
                        ))}
                    </div>
                </Box>
            </Modal>

            <ProjectEditorModal
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setCurrentPortfolio(null);
                }}
                initialProject={currentPortfolio}
                setSelectedProject={setCurrentPortfolio}
                userId={userId}
                onSave={handleSavePortfolio}
            />
        </Box>
    );
};

export default PortfolioGrid;