export const items = [
    {
        "label": "Renovation and Construction",
        "specialties": [
            {
                "label": "Interior Designer",
                "description": "Specializes in planning, designing, and managing the interior space to make it functional, aesthetic, and aligned with client needs.",
                "services": [
                    {
                        "label": "Space planning",
                        "keywords": ["interior space planning", "room layout design", "floor plan creation", "space optimization", "functional design"]
                    },
                    {
                        "label": "Color scheme selection",
                        "keywords": ["interior color selection", "paint color consultation", "color coordination", "palette design", "room color planning"]
                    },
                    {
                        "label": "Furniture selection",
                        "keywords": ["interior furniture selection", "furniture sourcing", "room furnishing", "custom furniture design", "furniture arrangement"]
                    },
                    {
                        "label": "Lighting design",
                        "keywords": ["interior lighting design", "lighting fixtures selection", "ambient lighting", "task lighting", "decorative lighting"]
                    },
                    {
                        "label": "Material selection",
                        "keywords": ["interior material selection", "surface material sourcing", "fabric selection", "wallpaper selection", "flooring material"]
                    },
                    {
                        "label": "Interior styling",
                        "keywords": ["home styling", "room decor", "interior accessorizing", "space decoration", "home staging"]
                    },
                    {
                        "label": "Custom cabinetry design",
                        "keywords": ["custom cabinets", "built-in cabinetry", "cabinet design service", "kitchen cabinets", "bathroom vanity design"]
                    },
                    {
                        "label": "Window treatment design",
                        "keywords": ["window coverings", "curtain design", "blinds selection", "drapery consultation", "shutters design"]
                    },
                    {
                        "label": "3D rendering",
                        "keywords": ["interior 3D rendering", "design visualization", "room rendering", "virtual interior design", "3D design modeling"]
                    },
                    {
                        "label": "Sustainable design",
                        "keywords": ["eco-friendly design", "sustainable interior design", "green materials", "energy-efficient design", "sustainable home solutions"]
                    }
                ]
            },
            {
                "label": "Home Appraiser",
                "description": "Provides professional evaluation of the value of residential properties for various purposes, including sales, insurance, and taxation.",
                "services": [
                    {
                        "label": "Market value appraisal",
                        "keywords": ["home market value", "property valuation", "real estate appraisal", "house value estimate", "residential property valuation"]
                    },
                    {
                        "label": "Pre-sale appraisal",
                        "keywords": ["home pre-sale valuation", "property selling price", "house appraisal for sale", "real estate pre-sale appraisal", "home value before sale"]
                    },
                    {
                        "label": "Mortgage appraisal",
                        "keywords": ["mortgage valuation", "home loan appraisal", "property value for mortgage", "mortgage property inspection", "real estate mortgage appraisal"]
                    },
                    {
                        "label": "Tax assessment",
                        "keywords": ["property tax valuation", "real estate tax assessment", "home tax appraisal", "property taxation value", "taxable property value"]
                    },
                    {
                        "label": "Insurance appraisal",
                        "keywords": ["home insurance valuation", "property insurance appraisal", "insured property value", "house insurance estimate", "real estate insurance appraisal"]
                    },
                    {
                        "label": "Estate planning appraisal",
                        "keywords": ["real estate estate planning", "property value for estate", "inheritance property appraisal", "estate property valuation", "estate tax appraisal"]
                    },
                    {
                        "label": "Divorce property appraisal",
                        "keywords": ["divorce real estate valuation", "property division appraisal", "home value for divorce settlement", "divorce property assessment", "house appraisal during divorce"]
                    },
                    {
                        "label": "Investment property appraisal",
                        "keywords": ["real estate investment appraisal", "property value for investors", "rental property valuation", "investment home appraisal", "investment property value"]
                    },
                    {
                        "label": "New construction appraisal",
                        "keywords": ["new home appraisal", "construction property valuation", "appraisal for new builds", "property value of new construction", "real estate new construction appraisal"]
                    },
                    {
                        "label": "Foreclosure appraisal",
                        "keywords": ["foreclosed property valuation", "home foreclosure appraisal", "foreclosure house value", "real estate foreclosure appraisal", "property value in foreclosure"]
                    }
                ]
            },
            {
                "label": "General Contractor",
                "description": "Oversees the entire construction or renovation projects, managing all phases from planning to completion, including subcontractors and materials.",
                "services": [
                    {
                        "label": "Project planning",
                        "keywords": ["construction projects planning", "building projects management", "renovation projects planning", "general contractor planning", "construction timeline"]
                    },
                    {
                        "label": "Budget management",
                        "keywords": ["construction budget control", "projects cost management", "building budget planning", "renovation cost estimation", "financial planning for construction"]
                    },
                    {
                        "label": "Subcontractor management",
                        "keywords": ["hiring subcontractors", "subcontractor coordination", "managing subcontractor schedules", "subcontractor agreements", "construction team management"]
                    },
                    {
                        "label": "Material procurement",
                        "keywords": ["construction materials sourcing", "building materials procurement", "material supply for construction", "construction material logistics", "procurement management"]
                    },
                    {
                        "label": "Site supervision",
                        "keywords": ["construction site supervision", "on-site management", "building site monitoring", "job site oversight", "construction safety supervision"]
                    },
                    {
                        "label": "Permit acquisition",
                        "keywords": ["building permits", "construction permit process", "renovation permits", "permit acquisition service", "legal construction documentation"]
                    },
                    {
                        "label": "Quality control",
                        "keywords": ["construction quality assurance", "projects quality inspection", "quality control in construction", "building standards compliance", "construction projects quality management"]
                    },
                    {
                        "label": "Safety management",
                        "keywords": ["construction site safety", "OSHA compliance", "construction safety protocols", "job site safety management", "worker safety supervision"]
                    },
                    {
                        "label": "Scheduling and timelines",
                        "keywords": ["construction scheduling", "projects timeline management", "building projects timelines", "renovation scheduling", "time management in construction"]
                    },
                    {
                        "label": "Cost estimation",
                        "keywords": ["construction cost estimating", "projects cost calculation", "building cost assessment", "renovation budget estimation", "contractor cost evaluation"]
                    }
                ]
            },
            {
                "label": "Plumber",
                "description": "Specializes in installing, repairing, and maintaining plumbing systems in residential, commercial, and industrial settings.",
                "services": [
                    {
                        "label": "Pipe installation",
                        "keywords": ["pipe fitting", "water pipe installation", "sewer pipe installation", "plumbing pipe setup", "gas pipe fitting"]
                    },
                    {
                        "label": "Leak repair",
                        "keywords": ["pipe leak repair", "faucet leak fixing", "water leak detection", "sewer leak repair", "plumbing leak solutions"]
                    },
                    {
                        "label": "Drain cleaning",
                        "keywords": ["clogged drain cleaning", "sewer drain cleaning", "plumbing drain unclogging", "blocked drain service", "drain maintenance"]
                    },
                    {
                        "label": "Water heater installation",
                        "keywords": ["water heater setup", "hot water system installation", "tankless water heater installation", "gas water heater setup", "electric water heater installation"]
                    },
                    {
                        "label": "Toilet repair",
                        "keywords": ["toilet fixing", "toilet leak repair", "toilet clog repair", "toilet installation service", "plumbing toilet maintenance"]
                    },
                    {
                        "label": "Fixture installation",
                        "keywords": ["sink installation", "faucet installation", "showerhead setup", "bathtub installation", "plumbing fixture fitting"]
                    },
                    {
                        "label": "Sewer line repair",
                        "keywords": ["sewer pipe repair", "main sewer line fixing", "sewer backup repair", "sewer line replacement", "plumbing sewer service"]
                    },
                    {
                        "label": "Gas line installation",
                        "keywords": ["gas pipe fitting", "gas line setup", "gas leak repair", "natural gas line installation", "plumbing gas services"]
                    },
                    {
                        "label": "Water filtration system installation",
                        "keywords": ["water filter setup", "home water purification", "reverse osmosis installation", "whole house water filter", "water treatment system installation"]
                    },
                    {
                        "label": "Emergency plumbing services",
                        "keywords": ["emergency plumber", "24/7 plumbing service", "urgent plumbing repair", "plumbing emergency assistance", "immediate plumbing solutions"]
                    }
                ]
            },
            {
                "label": "Electrician",
                "description": "Specializes in the installation, maintenance, and repair of electrical systems in residential, commercial, and industrial properties.",
                "services": [
                    {
                        "label": "Electrical wiring installation",
                        "keywords": ["electrical wiring setup", "home wiring installation", "commercial wiring services", "new wiring installation", "building electrical wiring"]
                    },
                    {
                        "label": "Circuit breaker repair",
                        "keywords": ["circuit breaker fixing", "electrical panel repair", "breaker box service", "fuse box repair", "electrical breaker replacement"]
                    },
                    {
                        "label": "Lighting installation",
                        "keywords": ["indoor lighting setup", "outdoor lighting installation", "LED light installation", "custom lighting solutions", "residential lighting installation"]
                    },
                    {
                        "label": "Electrical outlet installation",
                        "keywords": ["outlet setup", "power socket installation", "electrical outlet repair", "GFCI outlet installation", "home outlet services"]
                    },
                    {
                        "label": "Ceiling fan installation",
                        "keywords": ["ceiling fan wiring", "fan setup service", "ceiling fan mounting", "electric fan installation", "fan replacement service"]
                    },
                    {
                        "label": "Home automation system installation",
                        "keywords": ["smart home setup", "home automation wiring", "automated lighting installation", "smart device integration", "home automation services"]
                    },
                    {
                        "label": "Surge protection installation",
                        "keywords": ["surge protector setup", "home surge protection", "electrical surge control", "whole house surge protection", "appliance surge protection"]
                    },
                    {
                        "label": "Electrical inspection and testing",
                        "keywords": ["home electrical inspection", "commercial electrical testing", "safety electrical check", "building electrical assessment", "wiring safety inspection"]
                    },
                    {
                        "label": "Generator installation",
                        "keywords": ["backup generator setup", "standby generator installation", "emergency power system", "home generator installation", "portable generator wiring"]
                    },
                    {
                        "label": "Emergency electrical services",
                        "keywords": ["emergency electrician", "24/7 electrical service", "urgent electrical repair", "power outage services", "electrical emergency assistance"]
                    }
                ]
            },
            {
                "label": "Carpenter",
                "description": "Specializes in constructing, installing, and repairing structures and fixtures made of wood, plywood, and other materials.",
                "services": [
                    {
                        "label": "Custom furniture building",
                        "keywords": ["custom furniture design", "bespoke furniture", "handmade furniture", "wooden furniture crafting", "custom woodworking projects"]
                    },
                    {
                        "label": "Cabinet installation",
                        "keywords": ["cabinet fitting", "kitchen cabinet installation", "bathroom cabinet setup", "custom cabinet making", "cabinetry services"]
                    },
                    {
                        "label": "Door and window frame installation",
                        "keywords": ["door frame fitting", "window frame installation", "door and window replacement", "frame carpentry", "custom frame construction"]
                    },
                    {
                        "label": "Deck and patio construction",
                        "keywords": ["deck building", "patio construction", "outdoor deck carpentry", "wooden patio setup", "deck repair and maintenance"]
                    },
                    {
                        "label": "Flooring installation",
                        "keywords": ["wooden floor installation", "hardwood flooring setup", "laminate floor fitting", "custom flooring services", "carpentry floor installation"]
                    },
                    {
                        "label": "Trim and molding installation",
                        "keywords": ["crown molding fitting", "baseboard installation", "decorative trim services", "custom molding setup", "wood trim carpentry"]
                    },
                    {
                        "label": "Staircase building and repair",
                        "keywords": ["wooden staircase construction", "staircase repair services", "custom stair design", "carpentry staircase installation", "handrail setup"]
                    },
                    {
                        "label": "Shelving and storage solutions",
                        "keywords": ["custom shelving", "storage unit construction", "built-in shelves", "wooden storage solutions", "carpentry shelving services"]
                    },
                    {
                        "label": "Wood framing",
                        "keywords": ["home wood framing", "construction framing services", "carpentry framing", "custom frame building", "structural wood framing"]
                    },
                    {
                        "label": "Wood restoration and repair",
                        "keywords": ["wood furniture restoration", "antique wood repair", "wood surface refinishing", "carpentry restoration services", "wood repair and maintenance"]
                    }
                ]
            },
            {
                "label": "Painter",
                "description": "Specializes in applying paint, stain, varnish, and other finishes to walls, ceilings, and other surfaces to protect and beautify structures.",
                "services": [
                    {
                        "label": "Interior painting",
                        "keywords": ["indoor painting", "room painting", "wall painting services", "ceiling painting", "home interior painting"]
                    },
                    {
                        "label": "Exterior painting",
                        "keywords": ["outdoor painting", "house exterior painting", "building facade painting", "external wall painting", "home exterior repainting"]
                    },
                    {
                        "label": "Wallpaper installation",
                        "keywords": ["wallpaper fitting", "decorative wallpapering", "wall covering installation", "custom wallpaper services", "interior wallpaper setup"]
                    },
                    {
                        "label": "Staining and varnishing",
                        "keywords": ["wood staining", "furniture varnishing", "floor staining services", "wood finishing", "carpentry staining and varnishing"]
                    },
                    {
                        "label": "Textured painting",
                        "keywords": ["decorative painting", "textured wall painting", "custom texture painting", "faux finish painting", "wall texture application"]
                    },
                    {
                        "label": "Ceiling painting",
                        "keywords": ["ceiling repainting", "custom ceiling painting", "interior ceiling finishing", "room ceiling decoration", "decorative ceiling painting"]
                    },
                    {
                        "label": "Spray painting",
                        "keywords": ["spray paint application", "commercial spray painting", "residential spray painting", "automotive spray painting", "industrial spray painting"]
                    },
                    {
                        "label": "Priming surfaces",
                        "keywords": ["surface priming", "wall priming", "paint preparation", "base coating", "primer application"]
                    },
                    {
                        "label": "Epoxy coating",
                        "keywords": ["epoxy floor coating", "industrial epoxy painting", "garage floor epoxy", "decorative epoxy coating", "epoxy surface protection"]
                    },
                    {
                        "label": "Paint stripping",
                        "keywords": ["paint removal", "surface paint stripping", "wall paint stripping", "furniture paint removal", "old paint stripping services"]
                    }
                ]
            },
            {
                "label": "Roofer",
                "description": "Specializes in the installation, repair, and maintenance of roofs, ensuring protection from weather and environmental conditions.",
                "services": [
                    {
                        "label": "Roof installation",
                        "keywords": ["new roof setup", "roof construction", "roofing installation services", "home roof installation", "commercial roofing installation"]
                    },
                    {
                        "label": "Roof repair",
                        "keywords": ["leaky roof repair", "shingle replacement", "roof damage fixing", "roof patching", "emergency roof repair"]
                    },
                    {
                        "label": "Roof inspection",
                        "keywords": ["roof condition assessment", "home roof inspection", "commercial roof inspection", "roof safety check", "roof damage evaluation"]
                    },
                    {
                        "label": "Roof maintenance",
                        "keywords": ["roof cleaning", "roof upkeep", "roofing maintenance services", "preventative roof care", "roofing system maintenance"]
                    },
                    {
                        "label": "Gutter installation",
                        "keywords": ["gutter setup", "rain gutter installation", "gutter replacement", "downspout installation", "seamless gutter services"]
                    },
                    {
                        "label": "Roof insulation",
                        "keywords": ["roof insulation installation", "attic insulation", "thermal roof insulation", "energy-efficient roofing", "insulation roof services"]
                    },
                    {
                        "label": "Flat roofing",
                        "keywords": ["flat roof installation", "flat roof repair", "commercial flat roofing", "flat roof maintenance", "flat roofing systems"]
                    },
                    {
                        "label": "Metal roofing",
                        "keywords": ["metal roof installation", "steel roofing", "aluminum roofing", "metal roof repair", "residential metal roofing"]
                    },
                    {
                        "label": "Slate roofing",
                        "keywords": ["slate roof installation", "natural slate roofing", "slate tile replacement", "slate roof maintenance", "custom slate roofing"]
                    },
                    {
                        "label": "Solar panel roofing",
                        "keywords": ["solar roof installation", "solar panel setup", "solar energy roofing", "integrated solar panels", "solar roof services"]
                    }
                ]
            },
            {
                "label": "Tiler",
                "description": "Specializes in laying and fixing tiles on floors, walls, and other surfaces in residential, commercial, and industrial properties.",
                "services": [
                    {
                        "label": "Floor tiling",
                        "keywords": ["floor tile installation", "ceramic floor tiles", "porcelain tile flooring", "stone tile flooring", "residential floor tiling"]
                    },
                    {
                        "label": "Wall tiling",
                        "keywords": ["wall tile installation", "bathroom wall tiling", "kitchen backsplash tiling", "decorative wall tiles", "commercial wall tiling"]
                    },
                    {
                        "label": "Mosaic tiling",
                        "keywords": ["mosaic tile installation", "custom mosaic design", "mosaic wall tiling", "mosaic floor tiles", "artistic tiling services"]
                    },
                    {
                        "label": "Grout repair",
                        "keywords": ["grout fixing", "tile grout repair", "grout replacement", "grout cleaning services", "tile grout maintenance"]
                    },
                    {
                        "label": "Tile repair and replacement",
                        "keywords": ["broken tile repair", "tile replacement services", "cracked tile fixing", "tile patching", "tile damage repair"]
                    },
                    {
                        "label": "Outdoor tiling",
                        "keywords": ["patio tiling", "outdoor tile installation", "garden tiling services", "poolside tiling", "outdoor ceramic tiles"]
                    },
                    {
                        "label": "Waterproofing for tiles",
                        "keywords": ["tile waterproofing", "bathroom waterproofing", "shower tile waterproofing", "waterproof tile sealant", "moisture barrier for tiles"]
                    },
                    {
                        "label": "Tile leveling",
                        "keywords": ["tile leveling system", "floor leveling for tiles", "wall tile leveling", "even tile installation", "precision tile fitting"]
                    },
                    {
                        "label": "Tile design consultation",
                        "keywords": ["tile design advice", "custom tile patterns", "tile layout planning", "decorative tile design", "tile selection guidance"]
                    },
                    {
                        "label": "Tile cleaning and maintenance",
                        "keywords": ["tile cleaning services", "tile maintenance", "tile polishing", "tile surface cleaning", "long-term tile care"]
                    }
                ]
            },
            {
                "label": "Mason",
                "description": "Specializes in building and repairing structures made of bricks, stones, concrete blocks, and other masonry materials.",
                "services": [
                    {
                        "label": "Bricklaying",
                        "keywords": ["brick wall construction", "bricklaying services", "masonry brickwork", "brick structure building", "brick repair and maintenance"]
                    },
                    {
                        "label": "Stone masonry",
                        "keywords": ["stone wall construction", "natural stone masonry", "stonework services", "stone repair and maintenance", "stone structure building"]
                    },
                    {
                        "label": "Concrete block installation",
                        "keywords": ["concrete block wall", "block masonry", "cinder block installation", "concrete block construction", "block wall repair"]
                    },
                    {
                        "label": "Chimney repair",
                        "keywords": ["chimney masonry repair", "chimney rebuilding", "chimney brick repair", "chimney structure maintenance", "masonry chimney services"]
                    },
                    {
                        "label": "Foundation repair",
                        "keywords": ["masonry foundation repair", "concrete foundation services", "foundation crack repair", "masonry structural support", "foundation stabilization"]
                    },
                    {
                        "label": "Paving installation",
                        "keywords": ["paver installation", "stone paving services", "concrete paving", "driveway paving", "patio paving"]
                    },
                    {
                        "label": "Retaining wall construction",
                        "keywords": ["retaining wall masonry", "stone retaining walls", "concrete retaining wall construction", "garden retaining wall", "structural retaining walls"]
                    },
                    {
                        "label": "Masonry restoration",
                        "keywords": ["historic masonry restoration", "brickwork restoration", "stone restoration services", "masonry surface restoration", "masonry repair and restoration"]
                    },
                    {
                        "label": "Stucco application",
                        "keywords": ["stucco wall application", "exterior stucco services", "decorative stucco", "stucco repair and maintenance", "masonry stucco installation"]
                    },
                    {
                        "label": "Tuckpointing",
                        "keywords": ["masonry tuckpointing", "brick joint repair", "mortar joint replacement", "brickwork tuckpointing", "masonry joint restoration"]
                    }
                ]
            }
        ]
    }];
